import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const BenefitsCard = ({ title, subtitle }) => (
	<div className={styles.benefitCard}>
		<div className={styles.title}>{title}</div>
		{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
	</div>
);

BenefitsCard.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string
};

export default BenefitsCard;
