import React from 'react';

import styles from './styles.module.scss';

const InfoLink = () => (
	<a
		className={styles.infoLink}
		href="https://www.culture.ru/s/kurs_pushka/"
		target="_blank"
	>
		Стать учреждением-участником программы
	</a>
);

export default InfoLink;
