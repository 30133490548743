import _ from 'underscore';
import React, { Children, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Navigation, Lazy, EffectFade } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const renderNavButton = (type, icon, className) => (
	<button type="button" className={cx(styles.button, styles[type], className)}>
		<Icon icon={icon} className={cx(styles.icon, styles[icon])} />
	</button>
);

const Slider = ({
	params,
	children,
	getSwiper,
	slidesPerView,
	className,
	lazy,
	showControlsButton,
	leftButtonClassName,
	rightButtonClassName,
	gutter
}) => {
	const [noSwiping, setNoSwiping] = useState(false);
	const [swiperRef, setSwiperRef] = useState(false);

	const calculateNoSwiping = useCallback((swiperInstance) => {
		if (
			swiperInstance &&
			!swiperInstance.destroyed &&
			swiperInstance.slides?.length &&
			swiperInstance.isBeginning
		) {
			const lastSlide = swiperInstance.slides[swiperInstance.slides.length - 1];

			let { slidesOffsetBefore, slidesOffsetAfter } = swiperInstance.params;

			if (typeof slidesOffsetBefore === 'function') {
				slidesOffsetBefore = slidesOffsetBefore();
			}

			if (typeof slidesOffsetAfter === 'function') {
				slidesOffsetAfter = slidesOffsetAfter();
			}

			const containerWidth =
				swiperInstance.$el?.outerWidth() -
				slidesOffsetBefore -
				slidesOffsetAfter;

			return lastSlide.offsetLeft + lastSlide.offsetWidth <= containerWidth + 5;
		}

		return false;
	}, []);

	const onInit = useCallback((swiperInstance) => {
		setSwiperRef(swiperInstance);
		getSwiper(swiperInstance);

		swiperInstance.on('resize', () => {
			swiperInstance.update();
			setNoSwiping(calculateNoSwiping(swiperInstance));
		});
	}, []);

	useEffect(
		() => () => {
			if (swiperRef && !swiperRef.destroyed) {
				swiperRef.destroy();
			}
		},
		[]
	);

	useEffect(() => {
		setNoSwiping(calculateNoSwiping(swiperRef));
	}, [swiperRef, children]);

	const sliderClass = cx(
		styles.slider,
		!swiperRef && styles.hidden,
		gutter && styles[`gutter_${gutter}`],
		className
	);

	let swiperParams = {
		slidesPerView,
		spaceBetween: 8,
		updateOnWindowResize: false,
		observer: true,
		noSwiping,
		onSwiper: onInit,
		navigation: {
			nextEl: `.${styles.button}.${styles.right}`,
			prevEl: `.${styles.button}.${styles.left}`
		},
		...params
	};

	if (lazy) {
		swiperParams = { ...swiperParams, lazy: true, preloadImages: false };
	}

	return (
		!_.isEmpty(children) && (
			<div className={sliderClass}>
				<Swiper {...swiperParams} modules={[Navigation, Lazy, EffectFade]}>
					{Children.map(
						children,
						(child) =>
							child && <SwiperSlide key={child.key}>{child}</SwiperSlide>
					)}
					{showControlsButton && (
						<>
							{renderNavButton('right', 'chevron-right', rightButtonClassName)}
							{renderNavButton('left', 'chevron-left', leftButtonClassName)}
						</>
					)}
				</Swiper>
			</div>
		)
	);
};

Slider.propTypes = {
	params: PropTypes.object,
	lazy: PropTypes.bool,
	getSwiper: PropTypes.func,
	slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.node,
	className: PropTypes.string,
	showControlsButton: PropTypes.bool,
	leftButtonClassName: PropTypes.string,
	rightButtonClassName: PropTypes.string,
	gutter: PropTypes.oneOf(['cardsWithTag', 'onlyMobile'])
};

Slider.defaultProps = {
	params: {},
	getSwiper: _.noop,
	lazy: false,
	showControlsButton: false,
	slidesPerView: 'auto'
};

export default memo(Slider);
