import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const SocialButton = ({ icon, title, url }) => (
	<a className={styles.button} href={url} title={title} target="_blank">
		<Icon icon={icon} className={styles.icon} />
	</a>
);

SocialButton.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	url: PropTypes.string
};

export default SocialButton;
