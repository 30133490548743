import PropTypes from 'prop-types';
import cx from 'classnames';
import { animated, useSpring, useSprings } from '@react-spring/web';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import Benefits from 'components/Layout/components/Benefits';
import Icon from 'components/Icon';
import Navigation from 'components/Layout/components/Navigation';

import DecorSeparator from '../DecorSeparator';

import styles from './styles.module.scss';

const multiLinesParams = [
	{
		coordLineY: 0,
		animationOffset: '50px'
	},
	{
		coordLineY: 10,
		animationOffset: '40px'
	},
	{
		coordLineY: 20,
		animationOffset: '30px'
	},
	{
		coordLineY: 30,
		animationOffset: '20px'
	},
	{
		coordLineY: 40,
		animationOffset: '10px'
	},
	{
		coordLineY: 50
	},
	{
		coordLineY: 60,
		animationOffset: '-10px'
	},
	{
		coordLineY: 70,
		animationOffset: '-20px'
	},
	{
		coordLineY: 80,
		animationOffset: '-30px'
	},
	{
		coordLineY: 90,
		animationOffset: '-40px'
	},
	{
		coordLineY: 100,
		animationOffset: '-50px'
	}
];

const waveStartPath = {
	rowOne:
		'M2 23L17.75 38L33.5 23L49.25 38L65 23L80.75 38L96.5 23L112.25 38L128 23L143.75 38L159.5 23L175.25 38L191 23L206.75 38L222.5 23L238.25 38L254 23',
	rowTwo:
		'M3 2L18.75 17L34.5 2L50.25 17L66 2L81.75 17L97.5 2L113.25 17L129 2M130 2L145.75 17L161.5 2L177.25 17L193 2L208.75 17L224.5 2L240.25 17L256 2'
};
const waveEndPath = {
	rowOne:
		'M2 37L17.75 22L33.5 37L49.25 22L65 37L80.75 22L96.5 37L112.25 22L128 37L143.75 22L159.5 37L175.25 22L191 37L206.75 22L222.5 37L238.25 22L254 37',
	rowTwo:
		'M3 16L18.75 1L34.5 16L50.25 1L66 16L81.75 1L97.5 16L113.25 1L129 16M130 16L145.75 1L161.5 16L177.25 1L193 16L208.75 1L224.5 16L240.25 1L256 16'
};

const PromoSection = ({ showDecor, promoBarRef, textBlocks }) => {
	const { asPath } = useRouter();

	const [multiLineSprings, multiLineSpringsApi] = useSprings(
		multiLinesParams.length,
		(index) => ({
			from: { transform: 'translateY(0)' },
			to: multiLinesParams[index]?.animationOffset
				? {
						transform: `translateY(${multiLinesParams[index].animationOffset})`
				  }
				: null,
			config: { duration: 800 },
			loop: { reverse: true },
			reset: true,
			onRest: (result) => {
				if (result?.value?.transform.includes('translateY(0)')) {
					multiLineSpringsApi.pause();
					// eslint-disable-next-line no-use-before-define
					circleSpringApi?.resume();
				}
			}
		})
	);

	const [circleSpring, circleSpringApi] = useSpring(() => ({
		from: { transform: 'scale(1) translateX(-55px)' },
		to: { transform: 'scale(0.5) translateX(-111px)' },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result?.value?.transform?.includes('scale(1)')) {
				circleSpringApi.pause();
				// eslint-disable-next-line no-use-before-define
				waveOneSpringApi?.resume();
				// eslint-disable-next-line no-use-before-define
				waveTwoSpringApi?.resume();
			}
		}
	}));

	const [waveOneSpring, waveOneSpringApi] = useSpring(() => ({
		from: { d: waveStartPath.rowOne },
		to: { d: waveEndPath.rowOne },
		loop: { reverse: true },
		config: { duration: 800 },
		reset: true,
		pause: true
	}));

	const [waveTwoSpring, waveTwoSpringApi] = useSpring(() => ({
		from: { d: waveStartPath.rowTwo },
		to: { d: waveEndPath.rowTwo },
		loop: { reverse: true },
		config: { duration: 800 },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.d.includes('M3 2L18.75 17L34.5')) {
				waveOneSpringApi.pause();
				waveTwoSpringApi.pause();
				// eslint-disable-next-line no-use-before-define
				rhombusOneSpringApi?.resume();
			}
		}
	}));

	const [rhombusOneSpring, rhombusOneSpringApi] = useSpring(() => ({
		from: { transform: 'rotate(0)', transformOrigin: '45px 44px' },
		to: { transform: 'rotate(180deg)', transformOrigin: '45px 44px' },
		config: { duration: 800, delay: 0 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.transform.includes('rotate(0)')) {
				rhombusOneSpringApi.pause();
			}

			// eslint-disable-next-line no-use-before-define
			rhombusTwoSpringApi?.resume();
		}
	}));

	const [rhombusTwoSpring, rhombusTwoSpringApi] = useSpring(() => ({
		from: { transform: 'rotate(0)', transformOrigin: '45px 95px' },
		to: { transform: 'rotate(180deg)', transformOrigin: '45px 95px' },
		config: { duration: 800, delay: 0 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.transform.includes('rotate(0)')) {
				rhombusTwoSpringApi.pause();
			}

			// eslint-disable-next-line no-use-before-define
			rhombusThreeSpringApi?.resume();
		}
	}));

	const [rhombusThreeSpring, rhombusThreeSpringApi] = useSpring(() => ({
		from: { transform: 'rotate(0)', transformOrigin: '45px 146px' },
		to: { transform: 'rotate(180deg)', transformOrigin: '45px 146px' },
		config: { duration: 800, delay: 0 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.transform.includes('rotate(0)')) {
				rhombusThreeSpringApi.pause();
				// eslint-disable-next-line no-use-before-define
				promoFigureOneSpringApi.resume();
			}
		}
	}));

	const [promoFigureOneSpring, promoFigureOneSpringApi] = useSpring(() => ({
		from: { cx: '27.3366', cy: '26.8596' },
		to: { cx: '23.2048', cy: '22.7273' },
		config: { duration: 400 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.cx.includes('27.3366')) {
				promoFigureOneSpringApi.pause();
				// eslint-disable-next-line no-use-before-define
				promoFigureThreeSpringApi?.resume();
			}
		}
	}));

	const [promoFigureTwoSpring, promoFigureTwoSpringApi] = useSpring(() => ({
		from: { transform: 'translate(1px, 1px)' },
		to: { transform: 'translate(-5px, -7px)' },
		config: { duration: 400 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.transform.includes('translate(1px, 1px)')) {
				promoFigureTwoSpringApi.pause();
				multiLineSpringsApi?.resume();
			}
		}
	}));

	const [promoFigureThreeSpring, promoFigureThreeSpringApi] = useSpring(() => ({
		from: { transform: 'translate(1px, 1px)' },
		to: { transform: 'translate(-8px, -3px)' },
		config: { duration: 400 },
		loop: { reverse: true },
		reset: true,
		pause: true,
		onRest: (result) => {
			if (result.value.transform.includes('translate(1px, 1px)')) {
				promoFigureThreeSpringApi.pause();
				promoFigureTwoSpringApi?.resume();
			}
		}
	}));

	useEffect(() => {
		if (asPath === '/') {
			multiLineSpringsApi?.start();
		} else {
			multiLineSpringsApi?.stop();
		}
	}, [asPath, multiLineSpringsApi]);

	return (
		<div className={styles.promoBar} ref={promoBarRef}>
			<div className={styles.promoBarInner}>
				<div className={styles.promoBarContent}>
					<div className={styles.promoBarNav}>
						<Navigation />
					</div>

					<div className={styles.Promo}>
						<div className={styles.Promo__Illustration}>
							<picture className={styles.Promo__WrapImage}>
								<source
									srcSet="/static/images/p-mobile-x1.png 238w, /static/images/p-mobile-x2.png 476w"
									sizes="(max-width: 1023px) 238px"
									media="(max-width: 1023px)"
								/>
								<source
									srcSet="/static/images/p-desktop-x1.png 292w, /static/images/p-desktop-x2.png 582w"
									sizes="(min-width: 1024px) 292px"
									media="(min-width: 1024px)"
								/>
								<img
									src="/static/images/p-desktop-x1.png"
									alt=""
									loading="lazy"
									className={styles.Promo__Image}
								/>
							</picture>
							<div
								className={cx(styles.Promo__Figure, styles.Promo__Figure_one)}
							>
								<svg
									width="51"
									height="50"
									viewBox="0 0 51 50"
									fill="none"
									className={styles.Promo__Svg}
								>
									<circle cx="23.2048" cy="22.7273" r="22.7273" fill="white" />
									<animated.circle
										cx={promoFigureOneSpring.cx}
										cy={promoFigureOneSpring.cy}
										r="22.7273"
										fill="#01FFFF"
									/>
									<mask
										id="figureOneMask"
										style={{ maskType: 'alpha' }}
										maskUnits="userSpaceOnUse"
										x="3"
										y="3"
										width="47"
										height="46"
									>
										<circle
											cx="26.5105"
											cy="26.0329"
											r="22.7273"
											fill="#66C6F1"
										/>
									</mask>
									<g mask="url(#figureOneMask)">
										<circle
											cx="23.2048"
											cy="22.7273"
											r="21.7273"
											stroke="#0C0722"
											strokeWidth="2"
										/>
									</g>
								</svg>
							</div>
							<div
								className={cx(styles.Promo__Figure, styles.Promo__Figure_two)}
							>
								<svg
									width="106"
									height="93"
									viewBox="0 0 106 93"
									fill="none"
									className={styles.Promo__Svg}
								>
									<path
										d="M52.0233 87.2148L2.43652 2.09083L101.61 2.09082L52.0233 87.2148Z"
										fill="white"
									/>
									<animated.path
										d="M56.1561 92.9999L6.56934 7.87599L105.743 7.87598L56.1561 92.9999Z"
										fill="#1EFB9E"
										style={{ ...promoFigureTwoSpring }}
									/>
									<path
										d="M52.0233 86.3884L2.43652 1.26441L101.61 1.2644L52.0233 86.3884Z"
										stroke="#0C0722"
										strokeWidth="2"
									/>
								</svg>
							</div>
							<div
								className={cx(styles.Promo__Figure, styles.Promo__Figure_three)}
							>
								<svg
									width="86"
									height="73"
									viewBox="0 0 86 73"
									fill="none"
									className={styles.Promo__Svg}
								>
									<path
										d="M70.9855 27.2323L59.1477 58.0708L26.1604 56.3421L17.6109 24.4351L45.3144 6.44423L70.9855 27.2323Z"
										fill="white"
									/>
									<animated.path
										d="M78.424 30.538L66.5862 61.3765L33.5989 59.6477L25.0494 27.7407L52.7528 9.74989L78.424 30.538Z"
										fill="#E9FB1E"
										style={{ ...promoFigureThreeSpring }}
									/>
									<path
										d="M45.2497 7.6786L69.7915 27.5522L58.4745 57.0342L26.9383 55.3815L18.7649 24.878L45.2497 7.6786Z"
										stroke="#0C0722"
										strokeWidth="2"
									/>
								</svg>
							</div>
							<div
								className={cx(styles.Promo__Figure, styles.Promo__Figure_wave)}
							>
								<svg
									width="68"
									height="9"
									viewBox="0 0 68 9"
									fill="none"
									className={styles.Promo__Svg}
								>
									<path
										d="M1.96582 1.48755L10.0237 7.56869L18.0815 1.48755L26.1394 7.56869L34.1972 1.48755L42.2551 7.56869L50.3129 1.48755L58.3708 7.56869L66.4286 1.48755"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="square"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className={styles.Promo__Content}>
							<Icon icon="logo" className={styles.Promo__Icon} />
							Программа популяризации культурных мероприятий среди молодежи
						</div>
					</div>

					<Benefits textBlocks={textBlocks} />
				</div>

				<div
					className={cx(
						styles.promoBarNavFigure,
						styles.promoBarNavFigure_figureLine
					)}
				>
					<svg className={styles.figureSvg} viewBox="0 0 131 102">
						{multiLineSprings.map((lineProps, index) => (
							<animated.rect
								key={multiLinesParams[index].coordLineY}
								y={multiLinesParams[index].coordLineY}
								width="131"
								height="2"
								fill="white"
								style={
									multiLinesParams[index]?.animationOffset ? lineProps : null
								}
							/>
						))}
					</svg>
				</div>

				<animated.div
					className={cx(
						styles.promoBarNavFigure,
						styles.promoBarNavFigure_figureCircle
					)}
					style={{
						...circleSpring
					}}
				>
					<svg className={styles.figureSvg} viewBox="0 0 111 111" fill="none">
						<circle
							opacity="0.5"
							cx="55.5"
							cy="55.5"
							r="54.5"
							stroke="white"
							strokeWidth="2"
						/>
					</svg>
				</animated.div>

				<div
					className={cx(
						styles.promoBarNavFigure,
						styles.promoBarNavFigure_figureWave
					)}
				>
					<svg width="258" height="39" viewBox="0 0 258 39" fill="none">
						<animated.path
							d={waveOneSpring.d}
							stroke="white"
							strokeWidth="2"
							strokeLinecap="square"
							strokeLinejoin="round"
						/>
						<animated.path
							d={waveTwoSpring.d}
							stroke="white"
							strokeWidth="2"
							strokeLinecap="square"
							strokeLinejoin="round"
						/>
					</svg>
				</div>

				<div
					className={cx(
						styles.promoBarNavFigure,
						styles.promoBarNavFigure_figureRhombus
					)}
				>
					<svg
						className={styles.figureSvg}
						width="88"
						height="190"
						viewBox="0 0 88 190"
						fill="none"
					>
						<animated.path
							d="M43.9999 1.41421L86.5855 43.9999L43.9999 86.5855L1.41421 43.9999L43.9999 1.41421Z"
							stroke="white"
							strokeWidth="2"
							style={{
								...rhombusOneSpring
							}}
						/>
						<animated.path
							d="M1.41446 95.003L44.0001 52.4174L86.5858 95.003L44.0001 137.589L1.41446 95.003Z"
							stroke="white"
							strokeWidth="2"
							style={{
								...rhombusTwoSpring
							}}
						/>
						<animated.path
							d="M86.5855 146.007L43.9999 188.593L1.41421 146.007L43.9999 103.422L86.5855 146.007Z"
							stroke="white"
							strokeWidth="2"
							style={{
								...rhombusThreeSpring
							}}
						/>
					</svg>
				</div>
			</div>
			<DecorSeparator collapse={!showDecor} />
		</div>
	);
};

PromoSection.propTypes = {
	showDecor: PropTypes.bool,
	textBlocks: PropTypes.object,
	promoBarRef: PropTypes.object
};

export default PromoSection;
