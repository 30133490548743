import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const Container = ({ children, className, width, gutter }) => (
	<div
		className={cx(
			styles.container,
			className,
			width && styles[`width_${width}`],
			gutter && styles[`gutter_${gutter}`]
		)}
	>
		{children}
	</div>
);

Container.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	width: PropTypes.oneOf(['small', 'medium']),
	gutter: PropTypes.oneOf(['noMobile'])
};

export default Container;
