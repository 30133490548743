import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BaseSlider from 'components/sliders/BaseSlider';

import styles from './styles.module.scss';

const OffsetSlider = ({ params, className, containerClassName, ...props }) => {
	const containerRef = useRef(null);
	const getOffsetLeft = () =>
		containerRef && containerRef.current ? containerRef.current.offsetLeft : 0;

	const sliderParams = {
		slidesOffsetBefore: getOffsetLeft,
		slidesOffsetAfter: getOffsetLeft,
		...params
	};

	const containerCx = cx(styles.container, containerClassName);

	return (
		<div className={className}>
			<div className={containerCx} ref={containerRef} />
			<div>
				<BaseSlider
					{...props}
					params={sliderParams}
					rightButtonClassName={cx(styles.button, styles.right)}
					leftButtonClassName={cx(styles.button, styles.left)}
				/>
			</div>
		</div>
	);
};

OffsetSlider.propTypes = {
	className: PropTypes.string,
	params: PropTypes.object,
	containerClassName: PropTypes.string
};

OffsetSlider.defaultProps = {
	params: {}
};

export default OffsetSlider;
