import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { screenBreakpoints } from 'utils/helpers/constants';

const SizeDependedBlock = ({ children, breakpoint }) => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const onResize = () => {
			const currentIsMobile = window.innerWidth < screenBreakpoints[breakpoint];

			setIsMobile(currentIsMobile);
		};

		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const [mobileChild = null, desktopChild = null] = children;

	return isMobile ? mobileChild : desktopChild;
};

SizeDependedBlock.propTypes = {
	children: PropTypes.node,
	breakpoint: PropTypes.oneOf(Object.keys(screenBreakpoints))
};

SizeDependedBlock.defaultProps = {
	children: [],
	breakpoint: 'tabletLarge'
};

export default SizeDependedBlock;
