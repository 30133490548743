import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import NavigationItem from '../NavigationItem';

import styles from './styles.module.scss';

const NavigationMobile = ({ items, currentPath }) => {
	const [showNav, setShowNav] = useState(false);
	const toggleNav = () => setShowNav(!showNav);

	return (
		<nav className={styles.navigationBar}>
			<button type="button" className={styles.toggleNav} onClick={toggleNav}>
				{showNav && <Icon icon="close" className={styles.close} />}
				Меню
			</button>
			{showNav && (
				<>
					<div className={styles.mobileNavContent}>
						{items.map((item) => (
							<NavigationItem
								key={item.title}
								title={item.title}
								url={item.url}
								external={item.external}
								active={currentPath?.includes(item.url)}
								isMobile
							/>
						))}
					</div>
					<button
						type="button"
						className={styles.mobileNavOverlay}
						onClick={toggleNav}
						aria-label="Закрыть навигацию"
					/>
				</>
			)}
		</nav>
	);
};

NavigationMobile.propTypes = {
	items: PropTypes.array,
	currentPath: PropTypes.string.isRequired
};

export default NavigationMobile;
