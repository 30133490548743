import React from 'react';

import SocialButton from '../SocialButton';

import styles from './styles.module.scss';

const date = new Date();
const currentYear = date.getFullYear() !== 2022 && `–${date.getFullYear()}`;

const Contacts = () => (
	<div className={styles.contacts}>
		<div className={styles.row}>
			<div className={styles.block}>
				<div className={styles.socials}>
					<SocialButton
						icon="telegram"
						title="Telegram"
						url="https://t.me/PRO_Push_Ka"
					/>
				</div>
				<div className={styles.text}>Для учреждений культуры</div>
			</div>
			<div className={styles.block}>
				<div className={styles.socials}>
					<SocialButton
						icon="vk"
						title="Вконтакте"
						url="https://vk.com/pushkakartaofficial"
					/>
					<SocialButton
						icon="telegram"
						title="Telegram"
						url="https://t.me/pushkakartaofficial"
					/>
				</div>
				<div className={styles.text}>Для участников программы</div>
			</div>
		</div>
		<div className={styles.row}>
			<div className={styles.block}>
				<small className={styles.copyright}>
					&copy; 2021{currentYear} ФКУ Цифровая культура. Все права защищены
				</small>
			</div>
			<div className={styles.block}>
				<div>
					Телефон горячей линии{' '}
					<a href="tel:+78001000645" className={styles.phone}>
						8&nbsp;800&nbsp;100&nbsp;06&nbsp;45
					</a>
				</div>
			</div>
		</div>
	</div>
);

export default Contacts;
