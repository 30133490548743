import React from 'react';
import getConfig from 'next/config';

import Container from 'components/Container';

import Brands from '../Brands';
import Contacts from '../Contacts';
import NavigationDefault from '../Navigation/components/NavigationDefault';
import InfoLink from '../InfoLink';

import styles from './styles.module.scss';

const {
	publicRuntimeConfig: { navigations }
} = getConfig();

const Footer = () => {
	const navWithoutExternalLink = navigations.filter((item) => !item.external);

	return (
		<footer className={styles.footer}>
			<div className={styles.content}>
				<Container>
					<div className={styles.nav}>
						<NavigationDefault
							items={navWithoutExternalLink}
							navPosition="footer"
						/>
					</div>
					<div className={styles.info}>
						<InfoLink />
					</div>
					<div className={styles.brands}>
						<Brands />
					</div>
					<Contacts />
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
