import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { getImageUrl } from '@culture/common-helpers/cultureUtils';
import _ from 'underscore';
import { BodyScrollLockProvider } from 'contexts/BodyScrollLockContext';

import useGlobalElementRef from 'hooks/useGlobalElementRef';

import Footer from './components/Footer';
import Header from './components/Header';
import SvgIcons from './components/SvgIcons';

import styles from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

const pageLoadTimeout = 2000;

const imageWidth = 1200;
const imageHeight = 630;

const Layout = ({
	children,
	title,
	description,
	typeHeader,
	textBlocks,
	image
}) => {
	const [isPageLoading, setPageLoading] = useState(true);
	const { asPath } = useRouter();
	const layoutRef = useGlobalElementRef('layout');
	const contentRef = useRef(null);

	const url = `${publicRuntimeConfig.baseUrl}${asPath}`;

	const imageSrc = _(image).isObject()
		? getImageUrl(image, {
				format: 'jpg',
				params: {
					crop: 'fill',
					gravity: 'center',
					width: imageWidth,
					height: imageHeight
				}
		  })
		: image;

	useEffect(() => {
		window.addEventListener('load', () => setPageLoading(false));
		setTimeout(() => setPageLoading(false), pageLoadTimeout);

		/* eslint-disable */
		window.ym =
			window.ym ||
			function () {
				(window.ym.a = window.ym.a || []).push(arguments);
			};
		window.ym.l = 1 * new Date();
		/* eslint-enable */

		window.ym(publicRuntimeConfig.services.yandex.analyticsId, 'init', {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true,
			ecommerce: 'dataLayer'
		});
	}, []);

	useEffect(() => {
		if (!isPageLoading) {
			const script = document.createElement('script');
			script.async = true;
			script.src = 'https://mc.yandex.ru/metrika/tag.js';

			const head = document.head || document.getElementsByTagName('head')[0];
			head.appendChild(script);
		}
	}, [isPageLoading]);

	return (
		<>
			<Head>
				<title>{title}</title>

				<meta name="description" content={description} />

				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="format-detection" content="telephone=no" />

				<meta
					name="yandex-verification"
					content={publicRuntimeConfig.services.yandex.webmaster}
				/>

				<meta property="og:type" content="website" />
				<meta property="og:url" content={url} />
				<meta name="og:title" content={title} />
				<meta property="og:description" content={description} />

				{imageSrc && (
					<>
						<meta property="og:image" content={imageSrc} />
						<meta property="og:image:width" content={imageWidth} />
						<meta property="og:image:height" content={imageHeight} />
					</>
				)}

				<link
					rel="preload"
					as="font"
					href="/static/fonts/Roboto/Roboto-Regular.woff"
					type="font/woff"
					crossOrigin="anonymous"
				/>
				<link
					rel="preload"
					as="font"
					href="/static/fonts/Roboto/Roboto-Medium.woff"
					type="font/woff"
					crossOrigin="anonymous"
				/>
				<link
					rel="preload"
					as="font"
					href="/static/fonts/Roboto/Roboto-Bold.woff"
					type="font/woff"
					crossOrigin="anonymous"
				/>
				<link
					rel="preload"
					as="font"
					href="/static/fonts/DelaGothicOne/dela-gothic-one-v4-cyrillic-regular.woff"
					type="font/woff"
					crossOrigin="anonymous"
				/>

				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/static/favicons/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/static/favicons/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/static/favicons/favicon-16x16.png"
				/>
				<link rel="manifest" href="/static/favicons/site.webmanifest" />
				<link
					rel="mask-icon"
					href="/static/favicons/safari-pinned-tab.svg"
					color="#5bbad5"
				/>
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
			</Head>

			<BodyScrollLockProvider>
				<div ref={layoutRef} className={styles.layout}>
					<Header type="main" typeHeader={typeHeader} textBlocks={textBlocks} />
					<main className={styles.main}>
						<div ref={contentRef}>{children}</div>
					</main>
					<Footer />
				</div>
			</BodyScrollLockProvider>

			<SvgIcons />
		</>
	);
};

Layout.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node.isRequired,
	typeHeader: PropTypes.string,
	textBlocks: PropTypes.object,
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

const LayoutBlock = forwardRef(({ children, className }, ref) => (
	<div ref={ref} className={cx(styles.block, className)}>
		{children}
	</div>
));

LayoutBlock.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string
};

Layout.defaultProps = {
	title:
		'Пушкинская карта. Программа популяризации культурных мероприятий среди молодежи',
	description:
		'Пушкинская карта для молодежи: что это, как получить, как оформить. Подробная информация: события, кто может участвовать, программа, учреждения культуры, билеты онлайн',
	image: '/static/images/default-meta.png'
};

export { Layout, LayoutBlock };
