import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const DecorSeparator = ({ collapse }) => (
	<div className={cx(styles.decorSeparator, collapse && styles.animateDecor)}>
		<div className={cx(styles.separatorWavePrimary, styles.separatorWave7)} />
		<div className={cx(styles.separatorWaveSecondary, styles.separatorWave3)} />
		<div className={cx(styles.separatorWaveSecondary, styles.separatorWave2)} />
	</div>
);

DecorSeparator.propTypes = {
	collapse: PropTypes.bool
};

DecorSeparator.defaultProps = {
	collapse: false
};

export default DecorSeparator;
