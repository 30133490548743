import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import cx from 'classnames';

import Container from 'components/Container';
import Icon from 'components/Icon';

import Navigation from '../Navigation';

import DecorSeparator from './components/DecorSeparator';

import styles from './styles.module.scss';
import PromoSection from './components/PromoSection';

const Header = ({ typeHeader, textBlocks }) => {
	const [showDecor, setShowDecor] = useState(true);
	const promoBar = useRef(null);

	useEffect(() => {
		const onScrollPage = () => {
			const bodyScrollTop = document.documentElement.scrollTop;
			let positionSeparator = 0;

			if (typeHeader === 'promo') {
				positionSeparator = promoBar.current.offsetHeight;
			}

			if (bodyScrollTop > positionSeparator) {
				setShowDecor(false);
			} else {
				setShowDecor(true);
			}
		};

		onScrollPage();

		window.addEventListener('scroll', onScrollPage);

		return () => window.removeEventListener('scroll', onScrollPage);
	}, [showDecor]);

	return (
		<>
			<header className={styles.header}>
				{typeHeader === 'promo' && (
					<PromoSection {...{ showDecor, textBlocks, promoBarRef: promoBar }} />
				)}
				<div
					className={cx(
						styles.defaultBar,
						typeHeader === 'promo' && styles.defaultBar_promoPage,
						typeHeader === 'promo' && !showDecor && styles.defaultBar_show
					)}
				>
					<Container className={styles.defaultBarInner}>
						<Link href="/" passHref>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a className={styles.defaultBarBrand}>
								<Icon icon="logo" className={styles.defaultBarLogo} />
							</a>
						</Link>
						<Navigation />
					</Container>
					<DecorSeparator collapse={typeHeader === 'promo' || !showDecor} />
				</div>
			</header>
		</>
	);
};

Header.propTypes = {
	typeHeader: PropTypes.oneOf(['promo']),
	textBlocks: PropTypes.object
};

export default Header;
