import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const NavigationItem = ({
	active,
	external,
	isMobile,
	title,
	url,
	navPosition
}) => (
	<div>
		{isMobile && external && <div className={styles.separator} />}
		<Link href={url} passHref>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a
				className={cx(styles.link, external && styles.link_external)}
				target={external && '_blank'}
			>
				<span
					className={cx(
						styles.linkInner,
						active && styles.linkInner_active,
						external && styles.linkInner_external,
						navPosition && styles[`linkInner_navPosition_${navPosition}`]
					)}
				>
					{title}
					{external && (
						<Icon icon="chevron-right" className={styles.externalIcon} />
					)}
				</span>
			</a>
		</Link>
	</div>
);

NavigationItem.propTypes = {
	active: PropTypes.bool,
	external: PropTypes.bool,
	isMobile: PropTypes.bool,
	title: PropTypes.string,
	url: PropTypes.string,
	navPosition: PropTypes.oneOf(['footer'])
};

export default NavigationItem;
