import React from 'react';
import cx from 'classnames';

import icons from './icons';

import styles from './styles.module.scss';

const brandsData = [
	{
		title: 'Министерство культуры Российской федерации',
		url: 'https://culture.gov.ru/',
		name: 'mincult'
	},
	{
		title: 'Минцифры России',
		url: 'https://digital.gov.ru/ru/',
		name: 'digitalGov'
	},
	{
		title: 'Национальные приоритеты',
		url: 'https://национальныеприоритеты.рф/',
		name: 'nationalProjects'
	},
	{
		title: 'Госуслуги',
		url: 'https://www.gosuslugi.ru/',
		name: 'gosuslugi'
	},
	{
		title: 'Почта Банк',
		url: 'https://www.pochtabank.ru/',
		name: 'pochtaBank'
	},
	{
		title: 'Культура.РФ',
		url: 'https://www.culture.ru/',
		name: 'culture'
	}
];

const Brands = () => (
	<div className={styles.brands}>
		{brandsData.map((brand) => (
			<a
				key={brand.name}
				className={styles.brand}
				href={brand.url}
				title={brand.title}
				target="_blank"
			>
				<div className={cx(styles.figure, styles[`figure_${brand.name}`])}>
					{icons[brand.name]}
				</div>
			</a>
		))}
	</div>
);

export default Brands;
