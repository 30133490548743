import React from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

import SizeDependedBlock from 'components/SizeDependedBlock';

import NavigationDefault from './components/NavigationDefault';
import NavigationMobile from './components/NavigationMobile';

const {
	publicRuntimeConfig: { navigations }
} = getConfig();

const Navigation = () => {
	const { asPath } = useRouter();

	return (
		<SizeDependedBlock>
			<NavigationMobile items={navigations} currentPath={asPath} />
			<NavigationDefault items={navigations} currentPath={asPath} />
		</SizeDependedBlock>
	);
};

export default Navigation;
