import React from 'react';
import PropTypes from 'prop-types';

import NavigationItem from '../NavigationItem';

import styles from './styles.module.scss';

const checkActiveItem = (item, currentPath) =>
	currentPath && item.keys?.some((key) => currentPath.includes(key));

const NavigationDefault = ({ items, currentPath, navPosition }) => (
	<nav className={styles.navigationDesktop}>
		{items.map((item) => (
			<NavigationItem
				key={item.title}
				title={item.title}
				url={item.url}
				external={item.external}
				active={checkActiveItem(item, currentPath)}
				navPosition={navPosition}
			/>
		))}
	</nav>
);

NavigationDefault.propTypes = {
	items: PropTypes.array,
	currentPath: PropTypes.string,
	navPosition: PropTypes.string
};

export default NavigationDefault;
