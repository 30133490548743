import React from 'react';
import PropTypes from 'prop-types';

import BenefitsCard from 'components/cards/BenefitsCard';
import Container from 'components/Container';
import OffsetSlider from 'components/sliders/OffsetSlider';
import SizeDependedBlock from 'components/SizeDependedBlock';

import styles from './styles.module.scss';

const dataBenefits = [
	{
		name: 'participants',
		title: 'Участники'
	},
	{
		name: 'program',
		title: 'Программа'
	},
	{
		name: 'denomination',
		title: 'Номинал'
	},
	{
		name: 'events',
		title: 'События'
	}
];

const Benefits = ({ textBlocks }) => (
	<SizeDependedBlock>
		<OffsetSlider params={{ spaceBetween: 20 }}>
			{dataBenefits.map((item) => (
				<BenefitsCard
					key={item.title}
					title={item.title}
					subtitle={textBlocks[item.name]?.text}
				/>
			))}
		</OffsetSlider>
		<Container>
			<div className={styles.benefits}>
				{dataBenefits.map((item) => (
					<BenefitsCard
						key={item.title}
						title={item.title}
						subtitle={textBlocks[item.name]?.text}
					/>
				))}
			</div>
		</Container>
	</SizeDependedBlock>
);

Benefits.propTypes = {
	textBlocks: PropTypes.object.isRequired
};

export default Benefits;
