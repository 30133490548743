import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const Icon = ({ icon, className }) => {
	const iconCx = cx(styles.icon, className);

	return (
		<svg className={iconCx}>
			<use
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={`#icon-${icon}`}
			/>
		</svg>
	);
};

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default Icon;
